import React from 'react';
import logo from './logo.svg';
import './App.css';
import Particles from 'react-particles-js';
import useWindowDimensions from "./GetWindowDimensions"
import * as pathseg from 'pathseg';

function App() {
  const { height, width } = useWindowDimensions();

  const text_width = 270;
  const particle_constant = 300;
  const multiplier = Math.min( width / text_width, 3);
  const num_particles = (particle_constant * multiplier)

  return (
      <Particles
          params={{
            "fps_limit": 28,
            "particles": {
              "collisions": {
                "enable": false
              },
              "number": {
                "value": num_particles,
                "density": {
                  "enable": false
                }
              },
              "line_linked": {
                "enable": true,
                "distance": 15,
                "opacity": 0.4
              },
              "move": {
                "speed": 0.1
              },
              "opacity": {
                "anim": {
                  "enable": true,
                  "opacity_min": 0.05,
                  "speed": 1,
                  "sync": false
                },
                "value": 0.4
              }
            },
            "polygon": {
              "enable": true,
              "scale": multiplier,
              "type": "inline",
              "move": {
                "radius": multiplier,
                'type': 'path'
              },
              "url": "dstephens.svg",
              "inline": {
                "arrangement": "equidistant"
              },
              "draw": {
                "enable": true,
                "stroke": {
                  "color": "rgba(255, 255, 255, .2)"
                }
              }
            },
            "retina_detect": false,
            "interactivity": {
              "events": {
                "onhover": {
                  "enable": true,
                  "mode": "bubble"
                }
              },
              "modes": {
                "bubble": {
                  "size": 6,
                  "distance": 40
                },
                "trail": {
                  "delay": 0.5,
                  "quantity": 1
                }
              }
            }
          }}
      />
  );
}

export default App;
